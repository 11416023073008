import { Add, Remove } from '@mui/icons-material';
import { InputAdornment } from '@mui/material';
import { NumberSelectorButton } from '@/components/common/button/NumberSelectorButton';
import { OutlinedField, OutlinedFieldProps } from '@/components/common/field/OutlinedField';
import React, { FC } from 'react';

export interface NumberSelectorFieldProps extends OutlinedFieldProps {
  minValue?: number;
  maxValue?: number;
  onIncreaseClick: (value: number | undefined) => void;
  onDecreaseClick: (value: number | undefined) => void;
}

export const NumberSelectorField: FC<NumberSelectorFieldProps> = (props: NumberSelectorFieldProps) => {
  const { value, minValue, maxValue, onIncreaseClick, onDecreaseClick, ...other } = props;
  return (
    <OutlinedField
      value={value}
      sx={{
        paddingLeft: 0,
        paddingRight: 0,
      }}
      startAdornment={
        <InputAdornment position={'start'}>
          <NumberSelectorButton
            onClick={() => onDecreaseClick(parseInt(String(value || '0')))}
            disabled={minValue != undefined ? parseInt(String(value || '0')) <= minValue : false}>
            <Remove />
          </NumberSelectorButton>
        </InputAdornment>
      }
      endAdornment={
        <InputAdornment position={'end'}>
          <NumberSelectorButton
            onClick={() => onIncreaseClick(parseInt(String(value || '0')))}
            disabled={maxValue != undefined ? parseInt(String(value || '0')) >= maxValue : false}>
            <Add />
          </NumberSelectorButton>
        </InputAdornment>
      }
      labelStyle={{ overflow: 'visible' }}
      inputProps={{
        style: {
          ...(!props.multiline && {
            textOverflow: 'ellipsis',
          }),
          width: '24px',
        },
      }}
      {...other}
    />
  );
};
