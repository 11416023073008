import { Grid, Typography } from '@mui/material';
import { LetterReceiptRow } from '@/components/common/LetterReceiptRow';
import { PaymentMethod } from '@/types/payment/PaymentMethod';
import { PaymentProvider } from '@/types/payment/PaymentProvider';
import { formatCurrency, getNumEnding } from '@/utils/string-utils';
import { getCommission, getCostWithCommission } from '@/utils/currency-utils';
import React, { FC } from 'react';

interface Props {
  countCharacters?: number;
  isFileAttached: boolean;
  crosswordAttached: boolean;
  sudokuAttached: boolean;
  pages?: number;
  letterCost?: number;
  authorizedLetterCost?: number;
  photoCost?: number;
  authorizedPhotoCost?: number;
  isAnswerOrdered: boolean;
  answersCount?: number;
  totalCost?: number;
  authorizedTotalCost?: number;
  paymentMethod: PaymentMethod;
  paymentProvider?: PaymentProvider;
  isInitiative?: boolean;
}

export const LetterReceipt: FC<Props> = (props: Props) => {
  const {
    countCharacters,
    isFileAttached,
    crosswordAttached,
    sudokuAttached,
    pages,
    letterCost,
    authorizedLetterCost,
    photoCost,
    authorizedPhotoCost,
    isAnswerOrdered,
    answersCount,
    totalCost,
    authorizedTotalCost,
    paymentMethod,
    paymentProvider,
    isInitiative,
  } = props;
  const calculateLetterCost = (): number | undefined => {
    if (paymentMethod === PaymentMethod.BALANCE) {
      return authorizedLetterCost;
    }
    return letterCost;
  };
  const calculatePhotoCost = (): number | undefined => {
    if (paymentMethod === PaymentMethod.BALANCE) {
      return authorizedPhotoCost;
    }
    return photoCost;
  };
  const calculateTotalCost = (): number | undefined => {
    if (paymentMethod === PaymentMethod.BALANCE) {
      return authorizedTotalCost;
    }
    return totalCost;
  };
  const calculatedLetterCost: number | undefined = calculateLetterCost();
  const calculatedPhotoCost: number | undefined = calculatePhotoCost();
  const calculatedTotalCost: number | undefined = calculateTotalCost();
  return (
    <Grid container={true} direction={'column'} spacing={4}>
      {((countCharacters && countCharacters > 0) ||
        isInitiative ||
        (isFileAttached && calculatedPhotoCost) ||
        (sudokuAttached && calculatedPhotoCost) ||
        (crosswordAttached && calculatedPhotoCost) ||
        ((paymentMethod === PaymentMethod.SBP || paymentMethod === PaymentMethod.ONLINE) &&
          (calculatedTotalCost || isInitiative))) && (
        <Grid item={true}>
          <Grid container={true} direction={'column'} spacing={1.5}>
            {countCharacters && countCharacters > 0 ? (
              <Grid item={true}>
                <LetterReceiptRow
                  label={`Письмо: ${countCharacters} ${getNumEnding(countCharacters || 0, [
                    'символ',
                    'символа',
                    'символов',
                  ])}, ${pages || '...'} ${getNumEnding(pages || 0, ['страница', 'страницы', 'страниц'])}`}
                  price={pages && calculatedLetterCost && formatCurrency(pages * calculatedLetterCost)}
                />
              </Grid>
            ) : (
              isInitiative && (
                <Grid item={true}>
                  <LetterReceiptRow
                    label={`Письмо: 1 страница`}
                    price={calculatedLetterCost && formatCurrency(calculatedLetterCost)}
                  />
                </Grid>
              )
            )}
            {isFileAttached && calculatedPhotoCost && (
              <Grid item={true}>
                <LetterReceiptRow label={'Фото'} price={formatCurrency(calculatedPhotoCost)} />
              </Grid>
            )}
            {sudokuAttached && calculatedPhotoCost && (
              <Grid item={true}>
                <LetterReceiptRow label={'Судоку'} price={formatCurrency(calculatedPhotoCost)} />
              </Grid>
            )}
            {crosswordAttached && calculatedPhotoCost && (
              <Grid item={true}>
                <LetterReceiptRow label={'Кроссворд'} price={formatCurrency(calculatedPhotoCost)} />
              </Grid>
            )}
            {isAnswerOrdered && calculatedLetterCost && (
              <Grid item={true}>
                <LetterReceiptRow
                  label={`Бланк ответа х${answersCount}`}
                  price={formatCurrency(calculatedLetterCost * (answersCount ? answersCount : 1))}
                />
              </Grid>
            )}
            {(paymentMethod === PaymentMethod.SBP || paymentMethod === PaymentMethod.ONLINE) &&
              (calculatedTotalCost || isInitiative) && (
                <Grid item={true}>
                  <LetterReceiptRow
                    label={'Сервисный сбор'}
                    price={
                      isInitiative && calculatedLetterCost
                        ? formatCurrency(getCommission(calculatedLetterCost, paymentMethod, paymentProvider))
                        : calculatedTotalCost &&
                          formatCurrency(getCommission(calculatedTotalCost, paymentMethod, paymentProvider))
                    }
                  />
                </Grid>
              )}
          </Grid>
        </Grid>
      )}
      <Grid item={true}>
        <Grid container={true} justifyContent={'space-between'}>
          <Grid item={true}>
            <Typography variant={'body1'} fontWeight={500} fontSize={24}>
              {'Итого'}
            </Typography>
          </Grid>
          <Grid item={true}>
            <Typography variant={'body1'} fontWeight={500} fontSize={24}>
              {isInitiative && calculatedLetterCost
                ? formatCurrency(getCostWithCommission(calculatedLetterCost, paymentMethod, paymentProvider))
                : calculatedTotalCost &&
                  formatCurrency(getCostWithCommission(calculatedTotalCost, paymentMethod, paymentProvider))}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
