import { MainCreateFormPayment } from '@/components/create/main/letter/MainCreateFormPayment';
import {
  ROUTE_CREATE_ANSWER_DEPARTMENT,
  ROUTE_CREATE_ANSWER_FEEDBACK,
  ROUTE_CREATE_LETTER_PAYMENT,
  ROUTE_CREATE_REPLY_ANSWER,
  ROUTE_CREATE_REPLY_LETTER,
  ROUTE_CREATE_REPLY_LETTER_ID,
  ROUTE_INBOX,
} from '@/app/routes';
import { RootState } from '@/app/store';
import { Route, Switch } from 'react-router-dom';
import { Theme } from '@mui/material/styles';
import { clearFormState, clearLetterDraftId } from '@/services/createFormSlice';
import { clearLetterSbpState } from '@/services/sbpSlice';
import { useAppDispatch, useAppSelector } from '@/app/hooks';
import { useMediaQuery } from '@mui/material';
import CreateFormLetter from '@/components/create/letter/CreateFormLetter';
import MainCreateFormPaymentMobile from '@/components/create/main/letter/MainCreateFormPaymentMobile';
import React, { FC, useEffect } from 'react';

export const ReplyBlock: FC = () => {
  const dispatch = useAppDispatch();
  const isMobile: boolean = useMediaQuery((theme: Theme) => {
    return theme.breakpoints.down('sm');
  });
  const createFormReducer = useAppSelector((state: RootState) => {
    return state.createForm;
  });
  const isLetterSent = createFormReducer.isLetterSent;
  useEffect(() => {
    return () => {
      if (isLetterSent) {
        dispatch(clearFormState());
        dispatch(clearLetterSbpState());
      }
      dispatch(clearLetterDraftId());
    };
  }, [isLetterSent]);

  return (
    <Switch>
      <Route exact={true} path={ROUTE_CREATE_REPLY_LETTER}>
        <CreateFormLetter
          tittle={'Напишите письмо'}
          mobileTittle={'Напишите письмо'}
          nextFormPath={ROUTE_CREATE_LETTER_PAYMENT}
          previousFormPath={ROUTE_INBOX}
          previousFormLabel={'Входящие'}
          errorRedirectPath={ROUTE_INBOX}
          isMobile={isMobile}
          isReply={true}
        />
      </Route>
      <Route path={ROUTE_CREATE_REPLY_LETTER_ID}>
        <CreateFormLetter
          tittle={'Ответить на письмо'}
          mobileTittle={'Ответить на письмо'}
          nextFormPath={ROUTE_CREATE_LETTER_PAYMENT}
          errorRedirectPath={ROUTE_INBOX}
          isMobile={isMobile}
          isReply={true}
        />
      </Route>
      <Route path={ROUTE_CREATE_REPLY_ANSWER}>
        {isMobile ? (
          <MainCreateFormPaymentMobile
            mobileTittle={'Оплатите ответ'}
            nextFormPath={ROUTE_CREATE_ANSWER_FEEDBACK}
            previousFormPath={ROUTE_CREATE_ANSWER_DEPARTMENT}
            errorRedirectPath={ROUTE_CREATE_ANSWER_DEPARTMENT}
            isMobile={isMobile}
            fromCreateAnswer={true}
            isAnswerReply={true}
          />
        ) : (
          <MainCreateFormPayment
            tittle={'Оплатите ответ'}
            nextFormPath={ROUTE_CREATE_ANSWER_FEEDBACK}
            previousFormPath={ROUTE_CREATE_ANSWER_DEPARTMENT}
            errorRedirectPath={ROUTE_CREATE_ANSWER_DEPARTMENT}
            fromCreateAnswer={true}
            isAnswerReply={true}
          />
        )}
      </Route>
    </Switch>
  );
};
