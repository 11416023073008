import { ButtonLink } from '@/components/common/ButtonLink';
import { ChevronRight } from '@mui/icons-material';
import { LetterTableDTO } from '@/types/letter/LetterTableDTO';
import {
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from '@mui/material';
import { SortIcon } from '@/app/icons/SortIcon';
import { SortRequestDTO } from '@/types/page/SortRequestDTO';
import { Theme } from '@mui/material/styles';
import { formatPrisonerShortName } from '@/utils/string-utils';
import { formatSmartDateTimeString } from '@/utils/date-utils';
import React, { FC, MouseEvent, useState } from 'react';

interface Props {
  isFetching: boolean;
  isLoading: boolean;
  letters: LetterTableDTO[];
  sortRequest: SortRequestDTO;
  onSort: (sortRequest: SortRequestDTO) => void;
  onLetterClick: (letter: LetterTableDTO) => void;
  onReplyClick: (letter: LetterTableDTO) => void;
}

export const InboxTable: FC<Props> = (props: Props) => {
  const { isFetching, isLoading, letters, sortRequest, onSort } = props;
  const loading: boolean = isFetching || isLoading;
  const [hoveredId, setHoveredId] = useState<number>(-1);
  const handleRowHover = (letterId: number) => (): void => {
    setHoveredId(letterId);
  };
  const handleRowClick = (letter: LetterTableDTO) => (): void => {
    const { onLetterClick } = props;
    if (letter) {
      onLetterClick(letter);
    }
  };
  const handleReplyClick = (letter: LetterTableDTO): void => {
    const { onReplyClick } = props;
    if (letter) {
      onReplyClick(letter);
    }
  };
  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <TableSortLabel
                color={'primary'}
                active={
                  sortRequest.properties.find((name: string) => {
                    return name == 'answer.processedAt' || name == 'answerDate';
                  }) != null
                }
                direction={sortRequest.direction === 'ASC' ? 'asc' : 'desc'}
                IconComponent={SortIcon}
                onClick={() =>
                  onSort({
                    properties: ['answer.processedAt', 'answerDate'],
                    direction: sortRequest.direction === 'ASC' ? 'DESC' : 'ASC',
                  })
                }>
                {'Дата'}
              </TableSortLabel>
            </TableCell>
            <TableCell>
              <TableSortLabel
                color={'primary'}
                active={
                  sortRequest.properties.find((name: string) => {
                    return name == 'number';
                  }) != null
                }
                direction={sortRequest.direction === 'ASC' ? 'asc' : 'desc'}
                IconComponent={SortIcon}
                onClick={() =>
                  onSort({
                    properties: ['number'],
                    direction: sortRequest.direction === 'ASC' ? 'DESC' : 'ASC',
                  })
                }>
                {'№ письма'}
              </TableSortLabel>
            </TableCell>
            <TableCell>
              <TableSortLabel
                color={'primary'}
                active={
                  sortRequest.properties.find((name: string) => {
                    return name == 'prisonerLastName' || name == 'prisonerFirstName' || name == 'prisonerMiddleName';
                  }) != null
                }
                direction={sortRequest.direction === 'ASC' ? 'asc' : 'desc'}
                IconComponent={SortIcon}
                onClick={() =>
                  onSort({
                    properties: ['prisonerLastName', 'prisonerFirstName', 'prisonerMiddleName'],
                    direction: sortRequest.direction === 'ASC' ? 'DESC' : 'ASC',
                  })
                }>
                {'Адресат'}
              </TableSortLabel>
            </TableCell>
            <TableCell />
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {(loading ? Array.from(new Array(8)) : letters).map((letter: LetterTableDTO, index: number) => (
            <TableRow
              key={loading ? index : letter.id}
              hover={true}
              onClick={handleRowClick(letter)}
              onMouseEnter={handleRowHover(loading ? index : letter.id)}
              onMouseLeave={handleRowHover(-1)}
              sx={{ cursor: 'pointer' }}>
              <TableCell sx={{ width: (theme: Theme) => theme.spacing(8) }}>
                {loading ? (
                  <Skeleton height={'20px'} />
                ) : (
                  <Typography variant={'body2'} whiteSpace={'nowrap'} fontWeight={letter?.unread ? 500 : 400}>
                    {formatSmartDateTimeString(letter.answerDate)}
                  </Typography>
                )}
              </TableCell>
              <TableCell sx={{ width: (theme: Theme) => theme.spacing(14) }}>
                {loading ? (
                  <Skeleton height={'20px'} />
                ) : (
                  <Typography variant={'body2'} whiteSpace={'nowrap'} fontWeight={letter?.unread ? 500 : 400}>
                    {letter.number}
                  </Typography>
                )}
              </TableCell>
              <TableCell sx={{ width: (theme: Theme) => theme.spacing(16) }}>
                {loading ? (
                  <Skeleton height={'20px'} />
                ) : (
                  <Typography
                    variant={'body2'}
                    fontWeight={letter?.unread ? 500 : 400}
                    color={(theme: Theme) => theme.colors.grayText}
                    whiteSpace={'nowrap'}>
                    {formatPrisonerShortName(letter.prisonerLastName, letter.prisonerFirstName)}
                  </Typography>
                )}
              </TableCell>
              <TableCell align={'right'} sx={{ verticalAlign: 'middle' }}>
                {!loading && (
                  <ButtonLink
                    color={'primary'}
                    onClick={(e: MouseEvent<HTMLButtonElement>) => {
                      e.stopPropagation();
                      handleReplyClick(letter);
                    }}
                    style={{
                      visibility: hoveredId === letter.id ? 'visible' : 'collapse',
                    }}>
                    <Typography variant={'h5'} align={'right'}>
                      {'Ответить'}
                    </Typography>
                  </ButtonLink>
                )}
              </TableCell>
              <TableCell
                align={'right'}
                sx={{
                  padding: 0,
                  width: (theme: Theme) => theme.spacing(3),
                  verticalAlign: 'middle',
                }}>
                {!loading && <ChevronRight sx={{ color: (theme: Theme) => theme.colors.tableBorder }} />}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
