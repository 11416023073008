import { AnswerSuccess } from '@/components/create/answer/AnswerSuccess';
import { CreateFormClient } from '@/components/create/letter/CreateFormClient';
import { CreateFormReceiver } from '@/components/create/letter/CreateFormReceiver';
import { MainCreateFormPayment } from '@/components/create/main/letter/MainCreateFormPayment';
import {
  ROUTE_MAIN_CREATE_ANSWER_AUTHOR,
  ROUTE_MAIN_CREATE_ANSWER_CLIENT,
  ROUTE_MAIN_CREATE_ANSWER_DEPARTMENT,
  ROUTE_MAIN_CREATE_ANSWER_FEEDBACK,
  ROUTE_MAIN_CREATE_ANSWER_PAYMENT,
  ROUTE_MAIN_CREATE_ANSWER_RECEIVER,
  ROUTE_MAIN_CREATE_FEEDBACK_SUCCESS,
} from '@/app/routes';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { RootState } from '@/app/store';
import { Theme } from '@mui/material/styles';
import { UserDTO } from '@/types/user/UserDTO';
import { clearFormState, clearLetterDraftId } from '@/services/createFormSlice';
import { clearLetterSbpState } from '@/services/sbpSlice';
import { getCurrentUser } from '@/services/authSlice';
import { useAppDispatch, useAppSelector } from '@/app/hooks';
import { useMediaQuery } from '@mui/material';
import AnswerFeedbackSent from '@/components/create/answer/AnswerFeedbackSent';
import CreateFormAuthorMobile from '@/components/create/letter/CreateFormAuthorMobile';
import CreateFormDepartment from '@/components/create/letter/CreateFormDepartment';
import CreateFormSender from '@/components/create/letter/CreateFormSender';
import MainCreateFormPaymentMobile from '@/components/create/main/letter/MainCreateFormPaymentMobile';
import React, { FC, useEffect } from 'react';

export const MainAnswerBlock: FC = () => {
  const isMobile: boolean = useMediaQuery((theme: Theme) => {
    return theme.breakpoints.down('sm');
  });

  const location = useLocation();
  const dispatch = useAppDispatch();
  const authUser: UserDTO | undefined = useAppSelector(getCurrentUser);
  const createFormReducer = useAppSelector((state: RootState) => {
    return state.createForm;
  });
  const isLetterSent = createFormReducer.isLetterSent;
  useEffect(() => {
    if (isLetterSent && !location.pathname.includes(ROUTE_MAIN_CREATE_ANSWER_PAYMENT)) {
      dispatch(clearFormState());
      dispatch(clearLetterSbpState());
    }
  }, [dispatch, location, isLetterSent]);
  useEffect(() => {
    return () => {
      dispatch(clearLetterDraftId());
    };
  }, []);

  return (
    <Switch>
      <Route exact={true} path={ROUTE_MAIN_CREATE_ANSWER_RECEIVER}>
        <CreateFormReceiver type={'answer'} />
      </Route>
      <Route exact={true} path={ROUTE_MAIN_CREATE_ANSWER_DEPARTMENT}>
        <CreateFormDepartment
          tittle={'Шаг 1 из 3: Выберите учреждение'}
          mobileTittle={'1/4: Выберите учреждение'}
          nextFormPath={ROUTE_MAIN_CREATE_ANSWER_AUTHOR}
          isMobile={isMobile}
        />
      </Route>
      <Route exact={true} path={ROUTE_MAIN_CREATE_ANSWER_AUTHOR}>
        {isMobile ? (
          <CreateFormSender
            mobileTittle={'2/4: Введите данные отправителя'}
            nextFormPath={ROUTE_MAIN_CREATE_ANSWER_CLIENT}
            previousFormPath={ROUTE_MAIN_CREATE_ANSWER_DEPARTMENT}
            previousFormLabel={'Шаг 1'}
            errorRedirectPath={ROUTE_MAIN_CREATE_ANSWER_DEPARTMENT}
            isMobile={isMobile}
            fromCreateAnswer={true}
          />
        ) : (
          <CreateFormClient
            tittle={'Шаг 2 из 3: Введите данные получателя и отправителя'}
            nextFormPath={ROUTE_MAIN_CREATE_ANSWER_PAYMENT}
            previousFormPath={ROUTE_MAIN_CREATE_ANSWER_DEPARTMENT}
            previousFormLabel={'Шаг 1'}
            errorRedirectPath={ROUTE_MAIN_CREATE_ANSWER_DEPARTMENT}
            isMobile={isMobile}
            recipientTittle={'Данные отправителя'}
            senderTittle={'Данные получателя'}
            fromCreateAnswer={true}
          />
        )}
      </Route>
      <Route exact={true} path={ROUTE_MAIN_CREATE_ANSWER_CLIENT}>
        {isMobile ? (
          <CreateFormAuthorMobile
            mobileTittle={'3/4: Введите данные получателя'}
            nextFormPath={ROUTE_MAIN_CREATE_ANSWER_PAYMENT}
            previousFormPath={ROUTE_MAIN_CREATE_ANSWER_AUTHOR}
            previousFormLabel={'Шаг 2'}
            errorRedirectPath={ROUTE_MAIN_CREATE_ANSWER_DEPARTMENT}
            isMobile={isMobile}
            fromCreateAnswer={true}
          />
        ) : (
          <CreateFormClient
            tittle={'Шаг 2 из 3: Введите данные получателя и отправителя'}
            nextFormPath={ROUTE_MAIN_CREATE_ANSWER_PAYMENT}
            previousFormPath={ROUTE_MAIN_CREATE_ANSWER_AUTHOR}
            previousFormLabel={'Шаг 1'}
            errorRedirectPath={ROUTE_MAIN_CREATE_ANSWER_DEPARTMENT}
            isMobile={isMobile}
            recipientTittle={'Данные отправителя'}
            senderTittle={'Данные получателя'}
            fromCreateAnswer={true}
          />
        )}
      </Route>
      <Route exact={true} path={ROUTE_MAIN_CREATE_ANSWER_PAYMENT}>
        {isMobile ? (
          <MainCreateFormPaymentMobile
            mobileTittle={'4/4: Оплатите ответ'}
            nextFormPath={ROUTE_MAIN_CREATE_ANSWER_FEEDBACK}
            previousFormPath={ROUTE_MAIN_CREATE_ANSWER_CLIENT}
            previousFormLabel={'Шаг 3'}
            errorRedirectPath={ROUTE_MAIN_CREATE_ANSWER_DEPARTMENT}
            isMobile={isMobile}
            fromCreateAnswer={true}
          />
        ) : (
          <MainCreateFormPayment
            tittle={'Шаг 3 из 3: Оплатите ответ'}
            nextFormPath={ROUTE_MAIN_CREATE_ANSWER_FEEDBACK}
            previousFormPath={ROUTE_MAIN_CREATE_ANSWER_AUTHOR}
            previousFormLabel={'Шаг 2'}
            errorRedirectPath={ROUTE_MAIN_CREATE_ANSWER_DEPARTMENT}
            fromCreateAnswer={true}
          />
        )}
      </Route>
      <Route exact={true} path={ROUTE_MAIN_CREATE_ANSWER_FEEDBACK}>
        <AnswerSuccess isMobile={isMobile} />
      </Route>
      <Route exact={true} path={ROUTE_MAIN_CREATE_FEEDBACK_SUCCESS}>
        <AnswerFeedbackSent />
      </Route>
      <Redirect to={authUser ? ROUTE_MAIN_CREATE_ANSWER_RECEIVER : ROUTE_MAIN_CREATE_ANSWER_DEPARTMENT} />
    </Switch>
  );
};
