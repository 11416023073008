import { AppDispatch } from '@/app/store';
import { AttachFileViewDTO } from '@/types/attachment/AttachFileViewDTO';
import { Divider, Grid, Skeleton, Stack, Typography, useMediaQuery } from '@mui/material';
import { EnclosureViewDTO } from '@/types/enclosure/EnclosureViewDTO';
import { ImagePreview } from '@/components/view/image/ImagePreview';
import { ImageViewDialog } from '@/components/view/image/ImageViewDialog';
import { LetterHistory } from '@/components/letter/history/LetterHistory';
import { LetterView } from '@/components/letter/LetterView';
import { LoadingButton } from '@/components/common/button/LoadingButton';
import { MobileLetterHistory } from '@/components/letter/history/MobileLetterHistory';
import { PageHeader } from '@/components/common/PageHeader';
import { ROUTE_INBOX, getCreateLetterReplyRoute } from '@/app/routes';
import { SenderInfoDTO } from '@/types/create/SenderInfoDTO';
import { Theme } from '@mui/material/styles';
import { formatShortDateTimeString } from '@/utils/date-utils';
import { getEnclosureName } from '@/utils/string-utils';
import { setCreateInfoFromDTO } from '@/services/createFormSlice';
import { useAppDispatch } from '@/app/hooks';
import { useGetAnswerQuery } from '@/services/api/answerApiSlice';
import { useGetEventLogQuery } from '@/services/api/eventApiSlice';
import { useGetLetterQuery } from '@/services/api/letterApiSlice';
import { useGetSenderToReplyMutation } from '@/services/api/createFormApiSlice';
import { useHistory, useParams } from 'react-router-dom';
import React, { FC, Fragment, useState } from 'react';

interface Params {
  id: string;
}

export const InboxLetterPage: FC = () => {
  const { id } = useParams<Params>();
  const history = useHistory();
  const dispatch: AppDispatch = useAppDispatch();
  const isMobile: boolean = useMediaQuery((theme: Theme) => {
    return theme.breakpoints.down('sm');
  });
  const letterId = Number(id);

  const { data: answer, isLoading: isAnswerLoading, isFetching: isAnswerFetching } = useGetAnswerQuery(letterId);
  const { data: letter, isFetching, isLoading } = useGetLetterQuery(letterId);
  const { data: events, isLoading: isEventLogLoading, isFetching: isEventLogFetching } = useGetEventLogQuery(letterId);

  const [getSender, { isLoading: isSenderLoading }] = useGetSenderToReplyMutation();

  const letterLoading: boolean = isFetching || isLoading;
  const answerLoading: boolean = isAnswerLoading || isAnswerFetching;
  const eventLogLoading: boolean = isEventLogLoading || isEventLogFetching;

  const [imageViewName, setImageViewName] = useState<string | undefined>(undefined);
  const [imageViewContent, setImageViewContent] = useState<string | undefined>(undefined);

  const handleReplyClick = (): void => {
    getSender(letterId)
      .unwrap()
      .then((dto: SenderInfoDTO): void => {
        dispatch(setCreateInfoFromDTO(dto));
      })
      .then(() => history.push(getCreateLetterReplyRoute(letterId)));
  };

  return (
    <Fragment>
      <Grid container={true} direction={'column'} wrap={'nowrap'} spacing={5}>
        <Grid item={true}>
          <Grid container={true} direction={'column'} spacing={isMobile ? 3 : 4}>
            <Grid item={true}>
              <Grid
                container={true}
                direction={'row'}
                alignItems={'center'}
                justifyContent={'space-between'}
                spacing={2}>
                <Grid item={true} xs={true}>
                  <Grid container={true} direction={'row'} alignItems={'center'} spacing={3}>
                    <Grid item={true}>
                      <PageHeader
                        previousFormPath={ROUTE_INBOX}
                        previousFormLabel={'Входящие'}
                        tittle={
                          <Stack direction={'row'} spacing={1}>
                            <span>{'Ответ на письмо №'}</span>
                            <span>{letterLoading ? <Skeleton width={'100px'} height={'32px'} /> : letter?.number}</span>
                          </Stack>
                        }
                        isMobile={isMobile}
                        mobileTittle={
                          <Stack direction={'row'} spacing={1}>
                            <span>{'Ответ на письмо №'}</span>
                            <span>{letterLoading ? <Skeleton width={'100px'} height={'32px'} /> : letter?.number}</span>
                          </Stack>
                        }
                      />
                    </Grid>
                    <Grid item={true} mt={isMobile ? 1 : 0}>
                      <Typography variant={'label1'} color={(theme: Theme) => theme.colors.grayText}>
                        {answerLoading ? (
                          <Skeleton width={'90px'} height={'16px'} />
                        ) : answer ? (
                          formatShortDateTimeString(answer.date)
                        ) : (
                          ''
                        )}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                {!isMobile && (
                  <Grid item={true}>
                    <LoadingButton
                      fullWidth={isMobile}
                      variant={'contained'}
                      color={'primary'}
                      sx={(theme: Theme) => ({
                        minWidth: theme.spacing(23),
                      })}
                      isLoading={isSenderLoading}
                      onClick={handleReplyClick}>
                      {'Ответить'}
                    </LoadingButton>
                  </Grid>
                )}
              </Grid>
            </Grid>
            <Grid item={true}>
              <Grid container={true} direction={'column'} spacing={4}>
                <Grid item={true}>
                  <Grid container={true} direction={'column'} spacing={3}>
                    <Grid item={true}>
                      <ImagePreview
                        title={`Ответ на письмо № ${answer?.letterNumber}`}
                        subtitle={answer ? formatShortDateTimeString(answer.date) : ''}
                        content={answer?.content || ''}
                        isMobile={isMobile}
                        isLoading={answerLoading}
                      />
                    </Grid>
                    {isMobile && (
                      <Grid item={true}>
                        <LoadingButton
                          fullWidth={isMobile}
                          variant={'contained'}
                          color={'primary'}
                          sx={(theme: Theme) => ({
                            minWidth: theme.spacing(23),
                          })}
                          isLoading={isSenderLoading}
                          onClick={handleReplyClick}>
                          {'Ответить'}
                        </LoadingButton>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
                <Grid item={true}>
                  <Divider />
                </Grid>
                <Grid item={true}>
                  <Grid container={true} direction={'row'} spacing={7}>
                    <Grid item={true} xs={12} sm={9}>
                      <Grid container={true} direction={'column'} spacing={isMobile ? 4 : 5}>
                        <Grid item={true}>
                          <Grid container={true} direction={'column'} spacing={3}>
                            <Grid item={true}>
                              <Grid
                                container={true}
                                direction={'row'}
                                alignItems={'center'}
                                justifyContent={'space-between'}
                                spacing={2}>
                                <Grid item={true} xs={true}>
                                  <Typography variant={isMobile ? 'h2' : 'h1'} whiteSpace={'nowrap'}>
                                    <Stack direction={'row'} spacing={1}>
                                      <span>{'Письмо №'}</span>
                                      <span>
                                        {letterLoading ? <Skeleton width={'100px'} height={'32px'} /> : letter?.number}
                                      </span>
                                    </Stack>
                                  </Typography>
                                </Grid>
                                <Grid item={true}>
                                  <Typography variant={'label1'} color={(theme: Theme) => theme.colors.grayText}>
                                    {letterLoading ? (
                                      <Skeleton width={'90px'} height={'16px'} />
                                    ) : letter ? (
                                      formatShortDateTimeString(letter.sentDate)
                                    ) : (
                                      ''
                                    )}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                            {isMobile && (
                              <Grid item={true}>
                                <MobileLetterHistory events={events} isLoading={eventLogLoading} />
                              </Grid>
                            )}
                            <Grid item={true}>
                              <LetterView
                                letter={letter}
                                isLoading={letterLoading}
                                onAttachFileClick={(attachFile: AttachFileViewDTO): void => {
                                  setImageViewName(attachFile?.name);
                                  setImageViewContent(attachFile?.content);
                                }}
                                onEnclosureClick={(enclosure: EnclosureViewDTO): void => {
                                  setImageViewName(getEnclosureName(enclosure?.type));
                                  setImageViewContent(enclosure?.content);
                                }}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    {!isMobile && (
                      <Grid item={true} xs={3}>
                        <LetterHistory events={events} isLoading={eventLogLoading} />
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <ImageViewDialog
        open={!!imageViewContent}
        content={imageViewContent || ''}
        title={
          <Grid container={true} direction={'row'} alignItems={'center'} spacing={2}>
            <Grid item={true}>
              <Typography variant={'h1'} fontSize={'24px'} lineHeight={'20px'}>
                {`Вложение письма № ${letter?.number}`}
              </Typography>
            </Grid>
            <Grid item={true}>
              <Typography
                variant={'label2'}
                fontSize={'12px'}
                lineHeight={'20px'}
                color={(theme: Theme) => theme.colors.grayText}>
                {imageViewName}
              </Typography>
            </Grid>
          </Grid>
        }
        onClose={() => setImageViewContent(undefined)}
      />
    </Fragment>
  );
};
