import { EnclosureInfoDTO } from '@/types/enclosure/EnclosureInfoDTO';
import { apiSlice } from '@/services/api/apiSlice';

export const enclosureApiSlice = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getEnclosureInfo: build.query<EnclosureInfoDTO, null>({
      query: () => ({
        url: '/enclosures/info',
        method: 'get',
      }),
    }),
  }),
});

export const { useGetEnclosureInfoQuery } = enclosureApiSlice;
