import { NumberSelectorField, NumberSelectorFieldProps } from '@/components/common/field/NumberSelectorField';
import { onlyNumberFormat } from '@/utils/string-utils';
import React, { ChangeEvent, FC } from 'react';

interface Props extends Omit<Omit<NumberSelectorFieldProps, 'onIncreaseClick'>, 'onDecreaseClick'> {
  onAnswerCountChange: (answerCount: number) => void;
}

export const AnswerCountSelectorField: FC<Props> = (props: Props) => {
  const { minValue, maxValue, helperText, onAnswerCountChange, ...other } = props;

  const handleChangeAnswersCount = (value: string | number): void => {
    let answersCount: number = parseInt(onlyNumberFormat(String(value)) || `${minValue || 0}`);
    if (answersCount < (minValue || 0)) {
      answersCount = minValue || 0;
    }
    if (answersCount > (maxValue || 10)) {
      answersCount = maxValue || 10;
    }
    onAnswerCountChange(answersCount);
  };

  const handleIncreaseAnswersCount = (value: number | undefined): void => {
    let answersCount: number = parseInt(String(value) || `${minValue || 0}`);
    if (answersCount >= (maxValue || 10)) {
      answersCount = maxValue || 10;
    } else {
      answersCount += 1;
    }
    handleChangeAnswersCount(answersCount);
  };

  const handleDecreaseAnswersCount = (value: number | undefined): void => {
    let answersCount: number = parseInt(String(value) || `${minValue || 0}`);
    if (answersCount <= (minValue || 0)) {
      answersCount = minValue || 0;
    } else {
      answersCount -= 1;
    }
    handleChangeAnswersCount(answersCount);
  };

  return (
    <NumberSelectorField
      name={'answersCount'}
      minValue={minValue || 0}
      maxValue={maxValue || 10}
      label={'Количество бланков ответа'}
      withLabel={true}
      isNotAbsoluteHelperText={true}
      helperText={helperText ? helperText : `Не более ${maxValue || 10} бланков`}
      onIncreaseClick={(value: number | undefined): void => {
        handleIncreaseAnswersCount(value);
      }}
      onDecreaseClick={(value: number | undefined): void => {
        handleDecreaseAnswersCount(value);
      }}
      onChange={(e: ChangeEvent<HTMLInputElement>): void => {
        handleChangeAnswersCount(e.target.value);
      }}
      {...other}
    />
  );
};
