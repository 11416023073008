import { AttachFileViewDTO } from '@/types/attachment/AttachFileViewDTO';
import { apiSlice } from '@/services/api/apiSlice';

export const attachFileApiSlice = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getAttachFile: build.mutation<AttachFileViewDTO, number>({
      query: (letterId: number) => ({
        url: '/attach/letter',
        method: 'get',
        params: {
          letterId: letterId,
        },
      }),
    }),
  }),
});

export const { useGetAttachFileMutation } = attachFileApiSlice;
