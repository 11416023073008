import { AttachButton } from '@/components/common/button/AttachButton';
import {
  Box,
  Grid,
  Skeleton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from '@mui/material';
import { Dot } from '@/components/common/Dot';
import { LetterSimpleStatus } from '@/types/letter/LetterSimpleStatus';
import { LetterTableDTO } from '@/types/letter/LetterTableDTO';
import { SortIcon } from '@/app/icons/SortIcon';
import { SortRequestDTO } from '@/types/page/SortRequestDTO';
import { StatusChip } from '@/components/common/StatusChip';
import { Theme } from '@mui/material/styles';
import { formatPrisonerShortName } from '@/utils/string-utils';
import { formatSmartDateTimeString } from '@/utils/date-utils';
import React, { FC, MouseEvent } from 'react';

interface Props {
  isFetching: boolean;
  isLoading: boolean;
  isAttachFileLoading?: boolean;
  letterId?: number;
  letters: LetterTableDTO[];
  sortRequest: SortRequestDTO;
  onSort: (sortRequest: SortRequestDTO) => void;
  onLetterClick: (letter: LetterTableDTO) => void;
  onAttachClick: (letter: LetterTableDTO) => void;
}

export const OutboxTable: FC<Props> = (props: Props) => {
  const { isFetching, isLoading, isAttachFileLoading, letterId, letters, sortRequest, onSort } = props;
  const loading: boolean = isFetching || isLoading;
  const handleRowClick = (letter: LetterTableDTO) => (): void => {
    const { onLetterClick } = props;
    if (letter) {
      onLetterClick(letter);
    }
  };
  const handleAttachClick = (letter: LetterTableDTO): void => {
    const { onAttachClick } = props;
    if (letter) {
      onAttachClick(letter);
    }
  };
  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell
              sx={{
                width: (theme: Theme) => theme.spacing(1),
                px: (theme: Theme) => theme.spacing(1),
              }}
            />
            <TableCell sx={{ pl: 0 }}>
              <TableSortLabel
                color={'primary'}
                active={
                  sortRequest.properties.find((name: string) => {
                    return name == 'sentDate';
                  }) != null
                }
                direction={sortRequest.direction === 'ASC' ? 'asc' : 'desc'}
                IconComponent={SortIcon}
                onClick={() =>
                  onSort({
                    properties: ['sentDate'],
                    direction: sortRequest.direction === 'ASC' ? 'DESC' : 'ASC',
                  })
                }>
                {'Дата'}
              </TableSortLabel>
            </TableCell>
            <TableCell>
              <TableSortLabel
                color={'primary'}
                active={
                  sortRequest.properties.find((name: string) => {
                    return name == 'number';
                  }) != null
                }
                direction={sortRequest.direction === 'ASC' ? 'asc' : 'desc'}
                IconComponent={SortIcon}
                onClick={() =>
                  onSort({
                    properties: ['number'],
                    direction: sortRequest.direction === 'ASC' ? 'DESC' : 'ASC',
                  })
                }>
                {'№ письма'}
              </TableSortLabel>
            </TableCell>
            <TableCell>
              <TableSortLabel
                color={'primary'}
                active={
                  sortRequest.properties.find((name: string) => {
                    return name == 'prisonerLastName' || name == 'prisonerFirstName' || name == 'prisonerMiddleName';
                  }) != null
                }
                direction={sortRequest.direction === 'ASC' ? 'asc' : 'desc'}
                IconComponent={SortIcon}
                onClick={() =>
                  onSort({
                    properties: ['prisonerLastName', 'prisonerFirstName', 'prisonerMiddleName'],
                    direction: sortRequest.direction === 'ASC' ? 'DESC' : 'ASC',
                  })
                }>
                {'Адресат'}
              </TableSortLabel>
            </TableCell>
            <TableCell align={'right'}>{'Статус'}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {(loading ? Array.from(new Array(8)) : letters).map((letter: LetterTableDTO, index: number) => (
            <TableRow
              key={loading ? index : letter.id}
              hover={true}
              onClick={handleRowClick(letter)}
              sx={{ cursor: 'pointer' }}>
              <TableCell
                sx={{
                  width: (theme: Theme) => theme.spacing(1),
                  px: (theme: Theme) => theme.spacing(1),
                }}>
                {!loading && letter.unread && <Dot />}
              </TableCell>
              <TableCell sx={{ pl: 0, width: (theme: Theme) => theme.spacing(8) }}>
                {loading ? (
                  <Skeleton height={'20px'} />
                ) : (
                  <Typography variant={'body2'} whiteSpace={'nowrap'}>
                    {formatSmartDateTimeString(letter.sentDate)}
                  </Typography>
                )}
              </TableCell>
              <TableCell sx={{ width: (theme: Theme) => theme.spacing(14) }}>
                {loading ? (
                  <Skeleton height={'20px'} />
                ) : (
                  <Typography variant={'body2'} whiteSpace={'nowrap'}>
                    {letter.number}
                  </Typography>
                )}
              </TableCell>
              <TableCell sx={{ width: (theme: Theme) => theme.spacing(16) }}>
                <Typography variant={'body2'} color={(theme: Theme) => theme.colors.grayText} whiteSpace={'nowrap'}>
                  {loading ? (
                    <Skeleton height={'20px'} />
                  ) : (
                    formatPrisonerShortName(letter.prisonerLastName, letter.prisonerFirstName)
                  )}
                </Typography>
              </TableCell>
              <TableCell sx={{ pr: 0 }}>
                {loading ? (
                  <Skeleton height={'20px'} />
                ) : (
                  <Grid container={true} direction={'row'} spacing={2} wrap={'nowrap'} alignItems={'center'}>
                    {letter.attachFiles.length > 0 && (
                      <Grid item={true}>
                        <AttachButton
                          isLoading={isAttachFileLoading && Number(letterId) === Number(letter.id)}
                          disabled={isAttachFileLoading}
                          onClick={(e: MouseEvent<HTMLButtonElement>) => {
                            e.stopPropagation();
                            handleAttachClick(letter);
                          }}
                        />
                      </Grid>
                    )}
                    <Grid item={true} xs={true}>
                      <Box position={'relative'} width={'100%'} minHeight={'20px'}>
                        <Typography
                          variant={'body2'}
                          color={(theme: Theme) => theme.colors.grayText}
                          textOverflow={'ellipsis'}
                          overflow={'hidden'}
                          whiteSpace={'nowrap'}
                          position={'absolute'}
                          width={'100%'}>
                          {letter.messageText}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item={true}>
                      <Stack direction={'row'} spacing={0.5} alignItems={'center'}>
                        {letter.statuses.map((status: LetterSimpleStatus, index: number) => (
                          <StatusChip key={index} status={status} />
                        ))}
                      </Stack>
                    </Grid>
                  </Grid>
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
