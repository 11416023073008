import { AttachmentCheckBox } from '@/components/create/letter/AttachmentCheckBox';
import { CreateFormAttachFile } from '@/components/create/letter/CreateFormAttachFile';
import { Grid } from '@mui/material';
import { formatCurrency } from '@/utils/string-utils';
import { useGetEnclosureInfoQuery } from '@/services/api/enclosureApiSlice';
import React, { ChangeEvent, FC } from 'react';

interface Props {
  attachmentCost: number;
  allowAttachments?: boolean;
  allowSudoku?: boolean;
  allowCrossword?: boolean;
  attachFile: File | undefined;
  onAttachFile: (file: File | undefined) => void;
  crosswordAttached?: boolean;
  onCrosswordAttached?: (e: ChangeEvent<HTMLInputElement>) => void;
  sudokuAttached?: boolean;
  onSudokuAttached?: (e: ChangeEvent<HTMLInputElement>) => void;
  isMobile?: boolean;
}

export const CreateFormAttachments: FC<Props> = (props: Props) => {
  const {
    attachmentCost,
    allowAttachments,
    allowSudoku,
    allowCrossword,
    attachFile,
    onAttachFile,
    crosswordAttached,
    onCrosswordAttached,
    sudokuAttached,
    onSudokuAttached,
    isMobile,
  } = props;
  const { data: info, isFetching, isError } = useGetEnclosureInfoQuery(null, { refetchOnMountOrArgChange: true });
  return (
    <Grid container={true} direction={'column'} alignItems={'start'} spacing={isMobile ? 1.5 : 2}>
      <Grid item={true}>
        <CreateFormAttachFile
          attachmentCost={attachmentCost}
          uploadEnabled={allowAttachments}
          attachFile={attachFile}
          onAttachFile={onAttachFile}
        />
      </Grid>
      <Grid item={true}>
        <Grid container={true} direction={'row'} wrap={'wrap'} columnSpacing={6} rowSpacing={1.5}>
          <Grid item={true}>
            <AttachmentCheckBox
              label={`Судоку + ${formatCurrency(attachmentCost, 0)}`}
              isUpdatable={false}
              captionText={'Каждый раз уникальное'}
              isLoading={isFetching}
              checked={sudokuAttached}
              disabled={(!allowAttachments || !allowSudoku || isError) && !sudokuAttached}
              onChange={onSudokuAttached}
            />
          </Grid>
          <Grid item={true}>
            <AttachmentCheckBox
              label={`Кроссворд + ${formatCurrency(attachmentCost, 0)}`}
              lastUpdatedDate={info?.crossword?.timestamp}
              isUpdatable={true}
              isLoading={isFetching}
              checked={crosswordAttached}
              disabled={
                (!allowAttachments || !allowCrossword || isError || !info?.crossword?.timestamp) && !crosswordAttached
              }
              onChange={onCrosswordAttached}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
