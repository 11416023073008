import { DepartmentDTO } from '@/types/DepartmentDTO';
import { PaymentMethod } from '@/types/payment/PaymentMethod';
import { PaymentProvider } from '@/types/payment/PaymentProvider';

export const getCostWithCommission = (cost: number, method: PaymentMethod, provider?: PaymentProvider): number => {
  return cost + getCommission(cost, method, provider);
};

export const getCommission = (cost: number, method: PaymentMethod, provider?: PaymentProvider): number => {
  if (method === PaymentMethod.ONLINE) {
    if (provider === PaymentProvider.ORC) {
      return getOrcCommission(cost);
    }
    return getAlfaCommission(cost);
  }
  if (method === PaymentMethod.SBP) {
    return getSbpCommission(cost);
  }
  return 0;
};

export const getSbpCommission = (cost: number): number => {
  return Math.ceil((((cost * 0.011 > 5.22 ? cost * 0.011 : 5.22) + 0.007 * cost) / 0.993) * 100) / 100;
};

export const getAlfaCommission = (cost: number): number => {
  const receiptPrice = cost * 0.011 > 5.22 ? cost * 0.011 : 5.22;
  const commission = ((receiptPrice + cost) * 0.023) / 0.977 > 3.5 ? ((receiptPrice + cost) * 0.023) / 0.977 : 3.5;
  return Math.ceil((receiptPrice + commission) * 100) / 100;
};

export const getOrcCommission = (cost: number): number => {
  return Math.round((cost < 1000 ? 5.12 + 0.02565 * cost : 0.03077 * cost) * 100) / 100;
};

export const getLetterCost = (isAuthenticated: boolean, department: DepartmentDTO | null): number => {
  return isAuthenticated
    ? department?.letterCost
      ? department.letterCost
      : 55
    : department?.unauthorizedLetterCost
    ? department.unauthorizedLetterCost
    : 65;
};

export const getPhotoCost = (isAuthenticated: boolean, department: DepartmentDTO | null): number => {
  return isAuthenticated
    ? department?.photoCost
      ? department.photoCost
      : 30
    : department?.unauthorizedPhotoCost
    ? department.unauthorizedPhotoCost
    : 40;
};

export const LETTER_COST = 55;
