import { CreateLetterDTO } from '@/types/create/CreateLetterDTO';
import { ErrorDTO } from '@/types/ErrorDTO';
import { Grid, Skeleton, Theme, Typography, useMediaQuery } from '@mui/material';
import { InitiativeLetterInfo } from '@/components/letter/initiative/InitiativeLetterInfo';
import { LetterBalance } from '@/components/create/unauthorized/LetterBalance';
import { LetterEmailDTO } from '@/types/letter/LetterEmailDTO';
import { LetterStatus } from '@/types/letter/LetterStatus';
import { PageHeader } from '@/components/common/PageHeader';
import { PaymentMethod } from '@/types/payment/PaymentMethod';
import { PaymentProvider } from '@/types/payment/PaymentProvider';
import { ROUTE_HOME, ROUTE_VIEW_INITIATIVE } from '@/app/routes';
import { RootState } from '@/app/store';
import { SbpMethod } from '@/types/sbp/SbpMethod';
import { clearFormState, getIdempotencyKey, getLetterNumber } from '@/services/createFormSlice';
import { clearSbpQrState, getSbpPaymentMethod, getSbpPaymentProvider, getSbpQrOutdated } from '@/services/sbpSlice';
import { format } from 'date-fns';
import { useAppSelector } from '@/app/hooks';
import { useDispatch } from 'react-redux';
import { useGetInitiativeLetterQuery, useSetEmailToLetterMutation } from '@/services/api/letterApiSlice';
import { useHistory, useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import React, { FC, Fragment, useCallback, useEffect } from 'react';

interface Params {
  uuid: string;
}

export const InitiativeLetterPayment: FC = () => {
  const { uuid } = useParams<Params>();
  const { enqueueSnackbar } = useSnackbar();

  const history = useHistory();
  const dispatch = useDispatch();

  const isMobile: boolean = useMediaQuery((theme: Theme) => {
    return theme.breakpoints.down('sm');
  });

  const idempotencyKey: string | undefined = useAppSelector(getIdempotencyKey);
  const letterNumber: number | undefined = useAppSelector(getLetterNumber);

  const qrOutdated: boolean = useAppSelector((state: RootState) =>
    getSbpQrOutdated(state, SbpMethod.LETTER, undefined, true, true)
  );

  const paymentMethod: PaymentMethod | undefined = useAppSelector((state: RootState) =>
    getSbpPaymentMethod(state, SbpMethod.LETTER, undefined, true, true)
  );

  const paymentProvider: PaymentProvider | undefined = useAppSelector((state: RootState) =>
    getSbpPaymentProvider(state, SbpMethod.LETTER, undefined, true, true)
  );

  const {
    data: letter,
    isLoading: isLetterLoading,
    error,
    refetch: getInitiativeLetter,
  } = useGetInitiativeLetterQuery(uuid, { skip: !uuid, refetchOnMountOrArgChange: true });

  const [setEmailToLetter, { isLoading: isEmailLoading }] = useSetEmailToLetterMutation();

  const isInProgress = (): boolean => {
    return Boolean(paymentMethod) && Boolean(paymentProvider);
  };

  const formatPrisonerName = (): string => {
    let prisonerName = '';
    if (letter?.prisonerLastName) {
      prisonerName += letter.prisonerLastName;
    }
    if (letter?.prisonerBirthDate) {
      prisonerName += ', ' + format(letter?.prisonerBirthDate, 'yyyy');
    }
    return prisonerName;
  };

  const formatPhone = (phone?: string): string => {
    return phone?.startsWith('+') ? phone : '+' + phone;
  };

  const createDummyLetter = (): CreateLetterDTO => {
    return {
      number: letter?.number,
      uuid: letter?.uuid,
      author: null,
      sender: null,
      email: letter?.recipientEmail,
    };
  };

  const handleEmailChange = async (email: string): Promise<void> => {
    if (letter?.number) {
      const dto: LetterEmailDTO = {
        number: letter?.number,
        email: email,
      };
      await setEmailToLetter(dto)
        .unwrap()
        .then((): void => {
          getInitiativeLetter();
        })
        .catch((e: { status: number; data: ErrorDTO }): void => {
          enqueueSnackbar(e.data.message, { variant: 'error' });
        });
    }
  };

  const handleSbpQrPaymentSuccess = (): void => {
    getInitiativeLetter();
  };

  const clearState = useCallback((): void => {
    clearSbpQrState(dispatch, SbpMethod.LETTER, undefined, false, true);
    dispatch(clearFormState());
  }, [dispatch]);

  useEffect((): void => {
    if (uuid) {
      if (error) {
        enqueueSnackbar((error as { status: number; data: ErrorDTO }).data.message, { variant: 'error' });
        history.replace(ROUTE_HOME);
      }
    } else {
      history.replace(ROUTE_HOME);
    }
  }, [enqueueSnackbar, error, history, uuid]);

  useEffect((): void => {
    if (letter?.status === LetterStatus.PAID_INITIATIVE) {
      enqueueSnackbar('Инициативное письмо оплачено', { variant: 'success' });
      history.replace(ROUTE_VIEW_INITIATIVE + '?uuid=' + letter?.uuid);
    }
  }, [enqueueSnackbar, history, letter?.status, letter?.uuid]);

  useEffect((): void => {
    if (qrOutdated) {
      clearState();
    }
    if (letter?.number && letterNumber && letter?.number != letterNumber) {
      clearState();
    }
  }, [clearState, dispatch, letter?.number, letterNumber, qrOutdated]);

  useEffect((): void => {
    const timerId: NodeJS.Timer = setInterval((): void => {
      if (!(!!letter && letter?.status === LetterStatus.PAID_INITIATIVE)) {
        getInitiativeLetter();
        clearInterval(timerId);
      }
    }, 30000);
  }, [getInitiativeLetter, letter]);

  const isPaymentSuccess: boolean = !!letter && letter?.status === LetterStatus.PAID_INITIATIVE;

  return (
    <Grid container={true} direction={'column'}>
      <Grid item={true} mb={2}>
        <PageHeader
          tittle={
            <Fragment>
              <Grid container={true} direction={'row'} spacing={1}>
                <Grid item={true}>
                  <Typography variant={'inherit'} component={'span'}>
                    {'Оплата инициативного письма'}
                  </Typography>
                </Grid>
                <Grid item={true}>
                  <Grid container={true} direction={'row'} spacing={1} wrap={'nowrap'}>
                    <Grid item={true}>
                      <Typography variant={'inherit'} component={'span'}>
                        {'№'}
                      </Typography>
                    </Grid>
                    <Grid item={true}>
                      <Typography variant={'inherit'} component={'span'}>
                        {isLetterLoading ? <Skeleton width={'136px'} /> : letter?.number}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Fragment>
          }
          isMobile={isMobile}
          mobileTittle={
            <Fragment>
              <Grid container={true} direction={'row'} spacing={1}>
                <Grid item={true}>
                  <Typography variant={'inherit'} component={'span'}>
                    {'Оплата инициативного письма'}
                  </Typography>
                </Grid>
                <Grid item={true}>
                  <Grid container={true} direction={'row'} spacing={1} wrap={'nowrap'}>
                    <Grid item={true}>
                      <Typography variant={'inherit'} component={'span'}>
                        {'№'}
                      </Typography>
                    </Grid>
                    <Grid item={true}>
                      <Typography variant={'inherit'} component={'span'}>
                        {isLetterLoading ? <Skeleton width={'136px'} /> : letter?.number}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Fragment>
          }
        />
      </Grid>
      <Grid item={true} mb={3}>
        <Grid container={true} columnSpacing={'55px'}>
          <Grid item={true} sm={7}>
            <InitiativeLetterInfo
              sender={formatPrisonerName()}
              receiver={letter?.recipientFullName}
              phone={formatPhone(letter?.recipientPhone)}
              email={letter?.recipientEmail}
              onEmailChange={handleEmailChange}
              isMobile={isMobile}
              isEmailConfirmed={!!letter?.recipientEmail}
              isEmailLoading={isEmailLoading}
              isLetterLoading={isLetterLoading}
            />
          </Grid>
          <Grid item={true} sm={5}>
            <LetterBalance
              letter={createDummyLetter()}
              crosswordAttached={false}
              sudokuAttached={false}
              isLetterSent={isInProgress()}
              isInitiative={true}
              isMobile={isMobile}
              letterCost={letter?.price}
              isInitiativeSubmitAllowed={!!letter?.recipientEmail}
              idempotencyKey={idempotencyKey}
              onSbpQrPaymentSuccess={handleSbpQrPaymentSuccess}
              isPaymentSuccess={isPaymentSuccess}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
