import { AttachFileViewDTO } from '@/types/attachment/AttachFileViewDTO';
import { AttachmentImage } from '@/components/view/AttachmentImage';
import { CollapseView } from '@/components/common/CollapseView';
import { EnclosureViewDTO } from '@/types/enclosure/EnclosureViewDTO';
import { Grid, Skeleton, Typography } from '@mui/material';
import { LetterViewDTO } from '@/types/letter/LetterViewDTO';
import { getEnclosureName, isNotBlank } from '@/utils/string-utils';
import React, { FC, Fragment } from 'react';

interface Props {
  letter?: LetterViewDTO;
  isLoading?: boolean;
  onAttachFileClick: (attachFile: AttachFileViewDTO) => void;
  onEnclosureClick: (enclosure: EnclosureViewDTO) => void;
}

export const LetterView: FC<Props> = (props: Props) => {
  const { isLoading, letter, onAttachFileClick, onEnclosureClick } = props;
  return isLoading ? (
    <Fragment>
      <Skeleton width={'100%'} height={'24px'} />
      <Skeleton width={'100%'} height={'24px'} />
      <Skeleton width={'100%'} height={'24px'} />
      <Skeleton width={'60%'} height={'24px'} />
    </Fragment>
  ) : (
    <CollapseView wrapHeight={320}>
      <Grid container={true} direction={'column'} spacing={3}>
        {isNotBlank(letter?.messageText) && (
          <Grid item={true}>
            <Typography
              variant={'body1'}
              sx={{
                whiteSpace: 'pre-line',
                wordBreak: 'break-word',
              }}>
              {letter?.messageText}
            </Typography>
          </Grid>
        )}
        {(Boolean(letter?.attachFiles?.length) || Boolean(letter?.enclosures?.length)) && (
          <Grid item={true}>
            <Grid container={true} direction={'row'} spacing={2}>
              {letter?.attachFiles?.map((attachFile: AttachFileViewDTO) => (
                <Grid item={true} key={attachFile.id}>
                  <AttachmentImage
                    fileName={attachFile.name}
                    content={attachFile.content}
                    onClick={(): void => onAttachFileClick(attachFile)}
                  />
                </Grid>
              ))}
              {letter?.enclosures?.map((enclosure: EnclosureViewDTO) => (
                <Grid item={true} key={enclosure.uuid}>
                  <AttachmentImage
                    fileName={getEnclosureName(enclosure.type)}
                    content={enclosure.content}
                    onClick={(): void => onEnclosureClick(enclosure)}
                  />
                </Grid>
              ))}
            </Grid>
          </Grid>
        )}
      </Grid>
    </CollapseView>
  );
};
