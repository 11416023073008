import { Box, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { isNotBlank } from '@/utils/string-utils';
import React, { FC } from 'react';

interface Props {
  content: string;
  fileName?: string;
  onClick?: () => void;
}

export const AttachmentImage: FC<Props> = (props: Props) => {
  const { content, fileName, onClick } = props;
  return (
    <Box
      position={'relative'}
      width={'150px'}
      height={'150px'}
      borderRadius={'12px'}
      border={(theme: Theme) => `1px solid ${theme.colors.borderColor}`}
      overflow={'hidden'}
      sx={{ cursor: onClick ? 'pointer' : 'default' }}
      onClick={onClick}>
      <img
        src={`data:image/jpeg;base64,${content}`}
        alt={''}
        style={{
          width: '100%',
          height: '100%',
          objectFit: 'cover',
          borderRadius: '12px',
        }}
      />
      {isNotBlank(fileName) && (
        <Typography
          sx={(theme: Theme) => ({
            color: 'white',
            position: 'absolute',
            bottom: 0,
            width: '100%',
            fontSize: '12px',
            lineHeight: '16px',
            padding: theme.spacing(1),
            backgroundColor: 'rgba(0, 0, 0, 0.8)',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
          })}>
          {fileName}
        </Typography>
      )}
    </Box>
  );
};
