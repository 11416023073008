import { BalancePaymentRadio } from '@/components/create/unauthorized/BalancePaymentRadio';
import { BankPaymentRadioSet } from '@/components/common/payment/BankPaymentRadioSet';
import { CardNumberPaymentRadio } from '@/components/common/payment/CardNumberPaymentRadio';
import { FormControl, Grid, RadioGroup, RadioGroupProps } from '@mui/material';
import { PaymentSettingsDTO } from '@/types/PaymentSettingsDTO';
import React, { FC, Fragment } from 'react';

interface Props extends RadioGroupProps {
  settings?: PaymentSettingsDTO;
  cardNumber: string;
  onCardNumberChange: (cardNumber: string) => void;
  cardCode: string;
  onCardCodeChange: (cardCode: string) => void;
  cardErrorMessage?: string;
  balanceAmount?: number;
  totalCost?: number;
  authorizedTotalCost?: number;
  isAuthenticated?: boolean;
  isLetterLoading?: boolean;
  isInitiative?: boolean;
}

export const LetterPaymentRadioGroup: FC<Props> = (props: Props) => {
  const {
    value,
    settings,
    cardNumber,
    onCardNumberChange,
    cardCode,
    onCardCodeChange,
    cardErrorMessage,
    balanceAmount,
    totalCost,
    authorizedTotalCost,
    isAuthenticated,
    isLetterLoading,
    isInitiative,
    ...other
  } = props;
  return (
    <FormControl component={'fieldset'}>
      <RadioGroup value={value} {...other}>
        <Grid container={true} direction={'column'} spacing={1.5}>
          <Grid item={true}>
            <BankPaymentRadioSet currentValue={value} settings={settings} />
          </Grid>
          {!isInitiative && (
            <Fragment>
              <Grid item={true}>
                <CardNumberPaymentRadio
                  currentValue={value}
                  cardNumber={cardNumber}
                  onCardNumberChange={onCardNumberChange}
                  cardCode={cardCode}
                  onCardCodeChange={onCardCodeChange}
                  errorMessage={cardErrorMessage}
                  isLetterLoading={isLetterLoading}
                />
              </Grid>
              <Grid item={true}>
                <BalancePaymentRadio
                  currentValue={value}
                  balanceAmount={balanceAmount}
                  totalCost={totalCost}
                  authorizedTotalCost={authorizedTotalCost}
                  isAuthenticated={isAuthenticated}
                />
              </Grid>
            </Fragment>
          )}
        </Grid>
      </RadioGroup>
    </FormControl>
  );
};
