import { AppDispatch, RootState } from '@/app/store';
import { CardPaymentRadioGroup } from '@/components/card/CardPaymentRadioGroup';
import { CardService } from '@/types/card/CardService';
import { ClientDialog } from '@/components/common/modal/ClientDialog';
import { CreatedSbpQrDTO } from '@/types/sbp/CreatedSbpQrDTO';
import { FixedButton } from '@/components/common/button/FixedButton';
import { Grid, Typography } from '@mui/material';
import { LoadingButton } from '@/components/common/button/LoadingButton';
import { MobileBar } from '@/components/home/MobileBar';
import { PaymentMethod } from '@/types/payment/PaymentMethod';
import { PaymentProvider } from '@/types/payment/PaymentProvider';
import { SbpCardPaymentContainer } from '@/components/common/sbp/SbpCardPaymentContainer';
import { Theme } from '@mui/material/styles';
import {
  clearCardCreateSbpState,
  clearCardRefillSbpState,
  getCardAmount,
  getCardEmail,
  isCardFillSuccess,
  setCardCreateFillSuccess,
  setCardRefillSuccess,
} from '@/services/sbpSlice';
import { formatCurrency } from '@/utils/string-utils';
import { getCommission, getCostWithCommission } from '@/utils/currency-utils';
import { useAppDispatch, useAppSelector } from '@/app/hooks';
import { useGetSystemSettingsQuery } from '@/services/api/settingsApiSlice';
import React, { ChangeEvent, FC, useCallback, useEffect } from 'react';

interface Props {
  open: boolean;
  paymentAmount: number;
  isMobile?: boolean;
  onClose?: () => void;
  cardService: CardService;
  paymentMethod: PaymentMethod;
  onPaymentMethodChange: (method: PaymentMethod) => void;
  paymentProvider?: PaymentProvider;
  onPaymentProviderChange: (provider?: PaymentProvider) => void;
  handlePayment: () => void;
  isLoading?: boolean;
  sbpOrderId?: string;
  sbpQR: CreatedSbpQrDTO | undefined;
}

export const CardPaymentDialog: FC<Props> = (props: Props) => {
  const {
    open,
    paymentAmount,
    isMobile,
    onClose,
    cardService,
    paymentMethod,
    onPaymentMethodChange,
    paymentProvider,
    onPaymentProviderChange,
    handlePayment,
    isLoading,
    sbpOrderId,
    sbpQR,
  } = props;

  const dispatch: AppDispatch = useAppDispatch();

  const cardAmount: number = useAppSelector((state: RootState) => getCardAmount(state, cardService));
  const isFillSuccess: boolean = useAppSelector((state: RootState) => isCardFillSuccess(state, cardService));
  const cardEmail: string | undefined = useAppSelector((state: RootState) => getCardEmail(state, cardService));

  const { data: systemSettings, refetch: getSystemSettings } = useGetSystemSettingsQuery();

  const handleRadioChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const paymentValue: { method: PaymentMethod; provider: PaymentProvider } = JSON.parse(e.target.value);
    handlePaymentValueChange(paymentValue.method, paymentValue.provider);
  };

  const handlePaymentValueChange = useCallback(
    (method: PaymentMethod, provider: PaymentProvider): void => {
      onPaymentMethodChange(method);
      onPaymentProviderChange(provider);
    },
    [onPaymentMethodChange, onPaymentProviderChange]
  );

  // используем setTimeout так как окно закрывается с задержкой, и чтобы у нас сайт менялся только после закрытия окна
  const handleClose = (): void => {
    if (onClose) {
      if (sbpOrderId || sbpQR || isFillSuccess) {
        if (cardService === CardService.CREATE) {
          setTimeout((): void => {
            dispatch(clearCardCreateSbpState());
            dispatch(setCardCreateFillSuccess(false));
          }, 500);
        }
        if (cardService === CardService.REFILL) {
          setTimeout((): void => {
            dispatch(clearCardRefillSbpState());
            dispatch(setCardRefillSuccess(false));
          }, 500);
        }
      }
      onClose();
    }
  };

  useEffect((): void => {
    getSystemSettings();
    if (paymentMethod === PaymentMethod.ONLINE) {
      if (paymentProvider === PaymentProvider.ALFA && !systemSettings?.alfaServiceEnabled) {
        handlePaymentValueChange(PaymentMethod.SBP, PaymentProvider.ALFA);
      }
      if (paymentProvider === PaymentProvider.ORC && !systemSettings?.orcServiceEnabled) {
        handlePaymentValueChange(PaymentMethod.SBP, PaymentProvider.ALFA);
      }
    }
  }, [
    getSystemSettings,
    handlePaymentValueChange,
    open,
    paymentMethod,
    paymentProvider,
    systemSettings?.alfaServiceEnabled,
    systemSettings?.orcServiceEnabled,
  ]);

  return (
    <ClientDialog
      open={open}
      isMobile={isMobile}
      label={cardService === CardService.CREATE ? 'Оформление Ф-карты' : 'Пополнение Ф-карты'}
      sxContent={(theme: Theme) => ({
        marginTop: theme.spacing(-1.5),
        [theme.breakpoints.up('xs')]: {
          maxWidth: '510px',
          minHeight: '506px',
        },
      })}
      actions={
        !(sbpOrderId || sbpQR || isFillSuccess) && (
          <Grid
            container={true}
            direction={isMobile ? 'column' : 'row'}
            justifyContent={'space-between'}
            alignItems={'center'}
            wrap={'nowrap'}>
            <Grid item={true} pr={{ xs: 0, sm: 2 }} pb={{ xs: 6, sm: 0 }}>
              <Typography
                variant={'body2'}
                whiteSpace={'pre-line'}
                color={(theme: Theme) => theme.colors.grayText}
                align={isMobile ? 'center' : 'left'}>
                {`Взимается сервисный сбор ${
                  paymentAmount > 0 ? formatCurrency(getCommission(paymentAmount, paymentMethod, paymentProvider)) : ''
                }`}
              </Typography>
            </Grid>
            <Grid item={true}>
              {isMobile ? (
                <MobileBar>
                  <FixedButton disabled={isLoading} variant={'contained'} color={'primary'} onClick={handlePayment}>
                    {'Оплатить'}
                  </FixedButton>
                </MobileBar>
              ) : (
                <LoadingButton
                  isLoading={isLoading}
                  fullWidth={true}
                  variant={'contained'}
                  color={'primary'}
                  onClick={handlePayment}
                  sx={{ minWidth: '176px' }}>
                  {'Оплатить'}
                </LoadingButton>
              )}
            </Grid>
          </Grid>
        )
      }
      handleCloseButton={handleClose}>
      {sbpOrderId || sbpQR || isFillSuccess ? (
        <SbpCardPaymentContainer
          amount={getCostWithCommission(cardAmount, PaymentMethod.SBP, PaymentProvider.ALFA)}
          isMobile={isMobile}
          email={cardEmail || ''}
          sbpOrderId={sbpOrderId}
          sbpQR={sbpQR}
          isCardFillSuccess={isFillSuccess}
          cardService={cardService}
        />
      ) : (
        <Grid container={true} direction={'column'} spacing={2.5}>
          <Grid item={true}>
            <Grid container={true} direction={'column'} spacing={1.5}>
              <Grid item={true}>
                <Typography variant={'label1'} color={(theme: Theme) => theme.colors.grayText}>
                  {cardService === CardService.CREATE ? 'Баланс карты' : 'Сумма пополнения'}
                </Typography>
              </Grid>
              <Grid item={true}>
                <Typography variant={'body1'}>{formatCurrency(paymentAmount, 0)}</Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item={true}>
            <Grid container={true} direction={'column'} spacing={2.25}>
              <Grid item={true}>
                <Typography variant={'label1'} color={(theme: Theme) => theme.colors.grayText}>
                  {'Способ оплаты'}
                </Typography>
              </Grid>
              <Grid item={true}>
                <CardPaymentRadioGroup
                  value={JSON.stringify({ method: paymentMethod, provider: paymentProvider })}
                  settings={systemSettings}
                  onChange={handleRadioChange}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
    </ClientDialog>
  );
};
