import { AnswerCountSelectorField } from '@/components/common/field/AnswerCountSelectorField';
import { AuthorDTO } from '@/types/AuthorDTO';
import { Buffer } from 'buffer';
import { Button, Grid, Theme, Typography } from '@mui/material';
import { CreateFormAttachments } from '@/components/create/letter/CreateFormAttachments';
import { CreateFormProps } from '@/types/create/CreateFormProps';
import {
  CreateFormState,
  deleteAttachFile,
  getAnswerOrder,
  getAnswersCount,
  getAttachFile,
  getAuthor,
  getCrosswordAttached,
  getLetterDraftId,
  getSender,
  getSudokuAttached,
  setAnswerOrderedForm,
  setAnswersCountForm,
  setBufferFile,
  setCrosswordAttachedState,
  setLetterDraftId,
  setLetterTextForm,
  setSudokuAttachedState,
  setTypeFile,
} from '@/services/createFormSlice';
import { DepartmentDTO } from '@/types/DepartmentDTO';
import { ErrorDTO } from '@/types/ErrorDTO';
import { FixedButton } from '@/components/common/button/FixedButton';
import { InfoIcon } from '@/app/icons/InfoIcon';
import { LabeledCheckBox } from '@/components/common/LabeledCheckBox';
import { LetterAlert } from '@/components/create/letter/LetterAlert';
import { LetterDraftIdDTO } from '@/types/letterDraft/LetterDraftIdDTO';
import { LetterRulesDialog } from '@/components/create/LetterRulesDialog';
import { LetterTextField } from '@/components/create/letter/LetterTextField';
import { MAX_LETTER_COUNT, MESSAGE_FETCH_ERROR } from '@/utils/validation-utils';
import { MobileBar } from '@/components/home/MobileBar';
import { PageHeader } from '@/components/common/PageHeader';
import { RegionDTO } from '@/types/RegionDTO';
import { RootState } from '@/app/store';
import { SaveLetterDraftDTO } from '@/types/letterDraft/SaveLetterDraftDTO';
import { SenderDTO } from '@/types/SenderDTO';
import { TypographyLink } from '@/components/common/TypographyLink';
import { UserDTO } from '@/types/user/UserDTO';
import { calculateAdditionalCost } from '@/utils/price-utils';
import { clearBalanceByLetterPageState } from '@/services/sbpSlice';
import { emojiValidation } from '@/validation/emojiValidation';
import { formatCurrency, isBlank } from '@/utils/string-utils';
import { getCurrentUser } from '@/services/authSlice';
import { getInboxLetterRoute } from '@/app/routes';
import { getLetterCost, getPhotoCost } from '@/utils/currency-utils';
import { useAppDispatch, useAppSelector } from '@/app/hooks';
import { useCheckImageMutation } from '@/services/api/letterApiSlice';
import { useDebounce } from '@/app/hooks/useDebounce';
import { useGetClientInfoQuery } from '@/services/api/createFormApiSlice';
import { useHistory, useParams } from 'react-router-dom';
import { useSaveLetterDraftMutation } from '@/services/api/letterDraftApiSlice';
import { useSnackbar } from 'notistack';
import React, { ChangeEvent, FC, Fragment, RefObject, useCallback, useEffect, useRef, useState } from 'react';
import TittleInfo from '@/components/create/TittleInfo';

interface Props {
  isReply?: boolean;
}

const CreateFormLetter: FC<CreateFormProps & Props> = (props: CreateFormProps & Props) => {
  const { id } = useParams<{ id: string }>();

  const { tittle, errorRedirectPath, nextFormPath, isMobile, mobileTittle, isReply } = props;
  let { previousFormPath, previousFormLabel } = props;

  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useAppDispatch();
  const history = useHistory();

  const authUser: UserDTO | undefined = useAppSelector(getCurrentUser);
  const createFormReducer: CreateFormState = useAppSelector((state: RootState) => {
    return state.createForm;
  });
  const department: DepartmentDTO | null = createFormReducer.department;
  const region: RegionDTO | null = createFormReducer.region;
  const sender: SenderDTO | null = useAppSelector(getSender);
  const author: AuthorDTO | null = useAppSelector(getAuthor);
  const initialLetterText: string = createFormReducer.letterText || '';

  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [letterText, setLetterText] = useState<string>(initialLetterText);
  const [letterDraftId, setDraftId] = useState<number | undefined>(useAppSelector(getLetterDraftId));
  const [isEmoji, setEmoji] = useState<boolean>(false);
  const [attachFile, setAttachFile] = useState<File | undefined>(useAppSelector(getAttachFile));
  const [answerRequired, setAnswerRequired] = useState<boolean>(useAppSelector(getAnswerOrder));
  const [answersCount, setAnswersCount] = useState<number>(useAppSelector(getAnswersCount));

  const [crosswordAttached, setCrosswordAttached] = useState<boolean>(useAppSelector(getCrosswordAttached));
  const [sudokuAttached, setSudokuAttached] = useState<boolean>(useAppSelector(getSudokuAttached));

  const [letterTextError, setLetterTextError] = useState<boolean>(false);

  const refDraftButton: RefObject<HTMLButtonElement> = useRef<HTMLButtonElement>(null);

  const [saveLetterDraft, { isLoading: isLetterDraftSaving }] = useSaveLetterDraftMutation();
  const [checkImage, { isLoading: isImageChecking }] = useCheckImageMutation();
  const { data: clientInfo } = useGetClientInfoQuery();

  const charactersPerPage: number = clientInfo?.charactersPerPage ? clientInfo.charactersPerPage : 2500;

  const photoCost: number = getPhotoCost(!!authUser, department);
  const letterCost: number = getLetterCost(!!authUser, department);
  const additionalCost: number = calculateAdditionalCost(
    answerRequired,
    answersCount,
    letterCost,
    !!attachFile,
    crosswordAttached,
    sudokuAttached,
    photoCost
  );

  const submitDisabled: boolean =
    (isBlank(letterText) && !attachFile && !answerRequired && !crosswordAttached && !sudokuAttached) ||
    !department?.allowSending ||
    !answersCount ||
    answersCount < 1 ||
    answersCount > 10 ||
    isImageChecking;

  if (!(department && region && sender && author)) {
    if (errorRedirectPath) {
      history.push(errorRedirectPath);
    }
  }

  if (isReply && id) {
    previousFormLabel = `Письмо № ${id}`;
    previousFormPath = getInboxLetterRoute(parseInt(id));
  }

  const handleValidEmoji = useDebounce<string>((letterText: string): void => {
    setEmoji(emojiValidation(letterText));
  }, 1000);

  const handleLetterText = (letterText: string): void => {
    if (letterTextError && letterText.length < MAX_LETTER_COUNT) {
      setLetterTextError(false);
    } else if (letterText.length >= MAX_LETTER_COUNT) {
      setLetterTextError(true);
      enqueueSnackbar(`Письмо не может содержать больше ${MAX_LETTER_COUNT} символов`, {
        variant: 'error',
        preventDuplicate: true,
      });
    }
    setLetterText(letterText);
    handleValidEmoji(letterText);
  };

  const handleAttachFile = (attachFile: File | undefined): void => {
    if (attachFile) {
      setAttachFile(attachFile);
    } else {
      dispatch(deleteAttachFile());
      setAttachFile(undefined);
    }
  };

  const handleCheckAnswerRequired = (): void => {
    if (answerRequired) {
      setAnswersCount(1);
    }
    setAnswerRequired(!answerRequired);
  };

  const handleCrosswordAttached = (e: ChangeEvent<HTMLInputElement>): void => {
    const checked: boolean = e.target.checked;
    setCrosswordAttached(checked);
  };

  const handleSudokuAttached = (e: ChangeEvent<HTMLInputElement>): void => {
    const checked: boolean = e.target.checked;
    setSudokuAttached(checked);
  };

  const handleSaveLetterDraft = async (): Promise<void> => {
    dispatch(setLetterTextForm(letterText));
    dispatch(setCrosswordAttachedState(crosswordAttached));
    dispatch(setSudokuAttachedState(sudokuAttached));
    dispatch(setAnswerOrderedForm(answerRequired));
    dispatch(setAnswersCountForm(answersCount));
    const dto: SaveLetterDraftDTO = {
      id: letterDraftId,
      department: department?.id,
      author: author,
      sender: sender,
      letter: letterText,
      isAnswerOrdered: answerRequired,
      answersCount: answersCount,
      crosswordAttached: crosswordAttached,
      sudokuAttached: sudokuAttached,
    };
    if (attachFile) {
      await checkImage(attachFile)
        .unwrap()
        .then((): void => {
          attachFile
            .arrayBuffer()
            .then((value: ArrayBuffer): void => {
              const buffer: string = Buffer.from(value).toString('base64');
              const type: string = attachFile.type;
              dispatch(setBufferFile(buffer));
              dispatch(setTypeFile(type));
            })
            .catch(() => dispatch(deleteAttachFile));
        })
        .catch((e: { status: number; data: ErrorDTO }): void => {
          enqueueSnackbar(e.data?.message ? e.data.message : MESSAGE_FETCH_ERROR, { variant: 'error' });
        });
    }
    await saveLetterDraft({
      letterDraft: dto,
      image: attachFile ? attachFile : undefined,
    })
      .unwrap()
      .then((letterDraftId: LetterDraftIdDTO): void => {
        console.log(letterDraftId);
        dispatch(setLetterDraftId(letterDraftId.id));
        setDraftId(letterDraftId.id);
      })
      .catch((e: { status: number; data: ErrorDTO }): void => {
        enqueueSnackbar(e.data.message ? e.data.message : MESSAGE_FETCH_ERROR, {
          variant: 'error',
        });
      });
  };

  const saveDraftTimer = useCallback((): void => {
    if (authUser && refDraftButton && refDraftButton.current) {
      refDraftButton.current.click();
    }
  }, [authUser]);

  const handleSubmit = (): void => {
    dispatch(setLetterTextForm(letterText));
    dispatch(setCrosswordAttachedState(crosswordAttached));
    dispatch(setSudokuAttachedState(sudokuAttached));
    dispatch(setAnswerOrderedForm(answerRequired));
    dispatch(setAnswersCountForm(answersCount));
    dispatch(clearBalanceByLetterPageState());
    if (attachFile) {
      checkImage(attachFile)
        .unwrap()
        .then((): void => {
          attachFile
            .arrayBuffer()
            .then((value: ArrayBuffer): void => {
              dispatch(setBufferFile(Buffer.from(value).toString('base64')));
              dispatch(setTypeFile(attachFile.type));
            })
            .catch(() => dispatch(deleteAttachFile));
          if (nextFormPath) {
            history.push(nextFormPath);
          }
        })
        .catch((e: { status: number; data: ErrorDTO }): void => {
          enqueueSnackbar(e.data?.message ? e.data.message : MESSAGE_FETCH_ERROR, { variant: 'error' });
        });
    } else {
      if (nextFormPath) {
        history.push(nextFormPath);
      }
    }
  };

  useEffect(() => {
    const timer: NodeJS.Timer = setInterval(saveDraftTimer, 15000);
    return (): void => {
      clearInterval(timer);
    };
  }, [saveDraftTimer]);

  return (
    <Fragment>
      <Grid
        container={true}
        direction={'column'}
        sx={{
          paddingBottom: (theme: Theme): string => (isMobile ? theme.spacing(6) : theme.spacing(0)),
        }}>
        <Grid item={true} mb={isMobile ? 1 : 2}>
          <PageHeader
            tittle={tittle}
            previousFormLabel={previousFormLabel}
            previousFormPath={previousFormPath}
            isMobile={isMobile}
            mobileTittle={mobileTittle}
          />
        </Grid>
        {(!isMobile || isReply) && (
          <Grid item={true} mb={3}>
            <TittleInfo
              region={region?.name}
              department={department?.name}
              prisonerFirstName={sender?.firstName}
              prisonerMiddleName={sender?.middleName}
              prisonerLastName={sender?.lastName}
              isChanged={isReply}
              isMobile={isMobile}
            />
          </Grid>
        )}
        {isReply && department?.message && (
          <Grid item={true} mb={3}>
            <LetterAlert error={!department.allowSending}>{department.message}</LetterAlert>
          </Grid>
        )}
        <Grid item={true} marginBottom={isMobile ? '48px' : '13px'}>
          <Grid container={true} justifyContent={isMobile ? 'flex-start' : 'flex-end'}>
            <Grid
              item={true}
              sx={{
                cursor: 'pointer',
                color: (theme: Theme) => theme.colors.primary,
              }}
              onClick={() => setOpenDialog(true)}>
              <Grid container={true} columnSpacing={0.5} alignItems={'center'}>
                <Grid item={true}>
                  <InfoIcon sx={{ display: 'block', width: '16px', height: '16px' }} />
                </Grid>
                <Grid item={true}>
                  <Typography variant={'body2'}>{'Какие письма не пройдут цензуру?'}</Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item={true}>
          <LetterTextField
            label={'Текст письма'}
            size={'medium'}
            value={letterText}
            error={letterTextError}
            onChange={(event: ChangeEvent<HTMLInputElement>): void => {
              handleLetterText(event.target.value);
            }}
            countCharacters={letterText.length}
            costPage={letterCost}
            charactersPerPage={charactersPerPage}
            additionalCost={additionalCost}
            rows={isMobile ? 7 : 11}
            inputProps={{ maxLength: MAX_LETTER_COUNT }}
          />
        </Grid>
        {isEmoji && (
          <Grid item={true} mt={isMobile ? 3 : 2}>
            <LetterAlert>
              <Typography variant={'body1'} component={'div'}>
                {'Эмодзи и специальные символы могут быть расценены цензором как тайнопись. '}
                <TypographyLink onClick={() => setOpenDialog(true)}>{'Подробнее'}</TypographyLink>
              </Typography>
            </LetterAlert>
          </Grid>
        )}
        <Grid item={true} mt={isMobile ? 3 : 4}>
          <Grid container={true} justifyContent={'space-between'} spacing={8}>
            <Grid item={true} sm={8}>
              <Grid container={true} direction={'column'} spacing={isMobile ? 1.5 : 2}>
                <Grid item={true}>
                  <CreateFormAttachments
                    attachmentCost={photoCost}
                    allowAttachments={department?.allowPhoto}
                    allowSudoku={department?.allowSudoku}
                    allowCrossword={department?.allowCrossword}
                    attachFile={attachFile}
                    onAttachFile={handleAttachFile}
                    crosswordAttached={crosswordAttached}
                    onCrosswordAttached={handleCrosswordAttached}
                    sudokuAttached={sudokuAttached}
                    onSudokuAttached={handleSudokuAttached}
                    isMobile={isMobile}
                  />
                </Grid>
                <Grid item={true}>
                  <Grid container={true} direction={'column'} spacing={2}>
                    <Grid item={true}>
                      <Grid container={true} direction={'column'} spacing={1}>
                        <Grid item={true}>
                          <LabeledCheckBox
                            label={'Получить ответ'}
                            fontWeight={500}
                            checked={answerRequired}
                            onClick={handleCheckAnswerRequired}
                          />
                        </Grid>
                        <Grid item={true}>
                          <Typography variant={'body2'} color={(theme: Theme) => theme.colors.grayText}>
                            {
                              'Выберите количество бланков, каждый из которых будет отправлен как отдельное письмо. Каждый ответ будет отображаться отдельно в личном кабинете.'
                            }
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    {answerRequired && (
                      <Grid item={true}>
                        <Grid container={true} direction={'row'} spacing={2} alignItems={'center'}>
                          <Grid item={true}>
                            <AnswerCountSelectorField value={answersCount} onAnswerCountChange={setAnswersCount} />
                          </Grid>
                          <Grid item={true}>
                            <Typography variant={'h4'}>{`+ ${formatCurrency(
                              letterCost * answersCount,
                              0
                            )}`}</Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            {isMobile && (
              <MobileBar>
                <FixedButton color={'primary'} variant={'contained'} disabled={submitDisabled} onClick={handleSubmit}>
                  {'Продолжить'}
                </FixedButton>
              </MobileBar>
            )}
            <Grid item={true} sm={4}>
              <Grid container={true} direction={'column'} spacing={3}>
                {!isMobile && (
                  <Grid item={true}>
                    <Button
                      fullWidth={true}
                      color={'primary'}
                      variant={'contained'}
                      size={'medium'}
                      disabled={submitDisabled}
                      onClick={handleSubmit}>
                      {'Продолжить'}
                    </Button>
                  </Grid>
                )}
                <Grid item={true}>
                  <Grid container={true} direction={'column'} spacing={1}>
                    <Grid item={true}>
                      <Button
                        ref={refDraftButton}
                        fullWidth={true}
                        variant={'outlined'}
                        onClick={handleSaveLetterDraft}
                        disabled={isLetterDraftSaving || !authUser}>
                        {'Сохранить как черновик'}
                      </Button>
                    </Grid>
                    {!authUser && (
                      <Grid item={true}>
                        <Typography variant={'body2'} color={(theme: Theme) => theme.colors.grayText}>
                          {'Черновик доступен для авторизованных пользователей.'}
                        </Typography>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <LetterRulesDialog
        open={openDialog}
        isMobile={isMobile}
        handleCloseButton={() => setOpenDialog(false)}
        onClose={() => setOpenDialog(false)}
      />
    </Fragment>
  );
};

export default CreateFormLetter;
