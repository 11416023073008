import { Grid, Typography } from '@mui/material';
import { PaymentBalanceInfo } from '@/components/create/unauthorized/PaymentBalanceInfo';
import { PaymentMethod } from '@/types/payment/PaymentMethod';
import { PaymentRadio, PaymentRadioProps } from '@/components/common/payment/PaymentRadio';
import { Theme } from '@mui/material/styles';
import { UnauthorizedPaymentBalanceChip2 } from '@/components/create/unauthorized/UnauthorizedPaymentBalanceChip2';
import { UnauthorizedPaymentBalanceSignUp } from '@/components/create/unauthorized/UnauthorizedPaymentBalanceSignUp';
import React, { FC, Fragment } from 'react';

interface Props extends PaymentRadioProps {
  currentValue?: string;
  balanceAmount?: number;
  totalCost?: number;
  authorizedTotalCost?: number;
  isAuthenticated?: boolean;
}

export const BalancePaymentRadio: FC<Props> = (props: Props) => {
  const { currentValue, balanceAmount, totalCost, authorizedTotalCost, isAuthenticated, ...other } = props;

  const valueEquals = (method: PaymentMethod): boolean => {
    const value: { method: PaymentMethod } = currentValue ? JSON.parse(currentValue) : { method: PaymentMethod.SBP };
    return value?.method === method;
  };

  return (
    <Fragment>
      <PaymentRadio
        {...other}
        value={JSON.stringify({ method: PaymentMethod.BALANCE })}
        label={<UnauthorizedPaymentBalanceChip2 authorizedTotalCost={authorizedTotalCost} />}
        caption={
          <Grid container={true} spacing={2}>
            <Grid item={true}>
              <Typography variant={'body2'} color={(theme: Theme) => theme.colors.grayText}>
                {'Стоимость для зарегистрированных пользователей'}
              </Typography>
            </Grid>
            {valueEquals(PaymentMethod.BALANCE) && (
              <Grid item={true}>
                {isAuthenticated && balanceAmount && totalCost ? (
                  <PaymentBalanceInfo amount={balanceAmount} price={totalCost} />
                ) : (
                  <UnauthorizedPaymentBalanceSignUp />
                )}
              </Grid>
            )}
          </Grid>
        }
      />
    </Fragment>
  );
};
