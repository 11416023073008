export const calculatePageCount = (characterCount: number, charactersPerPage: number): number => {
  return characterCount > 0 ? Math.floor(characterCount / (charactersPerPage + 1)) + 1 : 0;
};

export const calculatePageTotalCost = (pageCount: number, pageCost: number): number => {
  return pageCount * pageCost;
};

export const calculateAnswerTotalCost = (answerRequired: boolean, answerCount: number, answerCost: number): number => {
  return answerRequired ? answerCount * answerCost : 0;
};

export const calculateAttachmentTotalCost = (
  attachFileAttached: boolean,
  crosswordAttached: boolean,
  sudokuAttached: boolean,
  attachmentCost: number
): number => {
  let attachmentTotalCost = 0;
  if (attachFileAttached) {
    attachmentTotalCost += attachmentCost;
  }
  if (crosswordAttached) {
    attachmentTotalCost += attachmentCost;
  }
  if (sudokuAttached) {
    attachmentTotalCost += attachmentCost;
  }
  return attachmentTotalCost;
};

export const calculateAdditionalCost = (
  answerRequired: boolean,
  answerCount: number,
  answerCost: number,
  attachFileAttached: boolean,
  crosswordAttached: boolean,
  sudokuAttached: boolean,
  attachmentCost: number
): number => {
  let additionalCost = 0;
  additionalCost += calculateAnswerTotalCost(answerRequired, answerCount, answerCost);
  additionalCost += calculateAttachmentTotalCost(attachFileAttached, crosswordAttached, sudokuAttached, attachmentCost);
  return additionalCost;
};

export const calculateTotalCost = (
  pageCount: number,
  answerRequired: boolean,
  answerCount: number,
  attachFileAttached: boolean,
  crosswordAttached: boolean,
  sudokuAttached: boolean,
  letterCost: number,
  answerCost: number,
  attachmentCost: number
): number => {
  let totalCost = 0;
  totalCost += calculatePageTotalCost(pageCount, letterCost);
  totalCost += calculateAdditionalCost(
    answerRequired,
    answerCount,
    answerCost,
    attachFileAttached,
    crosswordAttached,
    sudokuAttached,
    attachmentCost
  );
  return totalCost;
};
