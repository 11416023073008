import { AnswerViewBlock } from '@/components/view/AnswerViewBlock';
import { AttachFileViewDTO } from '@/types/attachment/AttachFileViewDTO';
import { Divider, Grid, Skeleton, Stack, Typography, useMediaQuery } from '@mui/material';
import { EnclosureViewDTO } from '@/types/enclosure/EnclosureViewDTO';
import { ImageViewDialog } from '@/components/view/image/ImageViewDialog';
import { LetterHistory } from '@/components/letter/history/LetterHistory';
import { LetterView } from '@/components/letter/LetterView';
import { MobileLetterHistory } from '@/components/letter/history/MobileLetterHistory';
import { PageHeader } from '@/components/common/PageHeader';
import { ROUTE_OUTBOX } from '@/app/routes';
import { Theme } from '@mui/material/styles';
import { formatShortDateTimeString } from '@/utils/date-utils';
import { getEnclosureName } from '@/utils/string-utils';
import { useGetAnswerQuery } from '@/services/api/answerApiSlice';
import { useGetEventLogQuery } from '@/services/api/eventApiSlice';
import { useGetLetterQuery } from '@/services/api/letterApiSlice';
import { useParams } from 'react-router-dom';
import React, { FC, Fragment, useState } from 'react';

interface Params {
  id: string;
}

export const OutboxLetterPage: FC = () => {
  const { id } = useParams<Params>();
  const isMobile: boolean = useMediaQuery((theme: Theme) => {
    return theme.breakpoints.down('sm');
  });
  const letterId = Number(id);

  const { data: letter, isFetching, isLoading } = useGetLetterQuery(letterId);
  const { data: answer } = useGetAnswerQuery(letterId);
  const { data: events, isLoading: isEventLogLoading, isFetching: isEventLogFetching } = useGetEventLogQuery(letterId);

  const loading: boolean = isFetching || isLoading;
  const eventLogLoading: boolean = isEventLogLoading || isEventLogFetching;

  const [imageViewName, setImageViewName] = useState<string | undefined>(undefined);
  const [imageViewContent, setImageViewContent] = useState<string | undefined>(undefined);

  return (
    <Fragment>
      <Grid container={true} direction={'column'} wrap={'nowrap'} spacing={5}>
        <Grid item={true}>
          <Grid container={true} direction={'row'} spacing={7}>
            <Grid item={true} xs={12} sm={9}>
              <Grid container={true} direction={'column'} spacing={isMobile ? 3 : 4}>
                <Grid item={true}>
                  <Grid
                    container={true}
                    direction={'row'}
                    alignItems={'center'}
                    justifyContent={'space-between'}
                    spacing={2}>
                    <Grid item={true} xs={true}>
                      <PageHeader
                        previousFormPath={ROUTE_OUTBOX}
                        previousFormLabel={'Исходящие'}
                        tittle={
                          <Stack direction={'row'} spacing={1}>
                            <span>{'Письмо №'}</span>
                            <span>{loading ? <Skeleton width={'100px'} height={'32px'} /> : letter?.number}</span>
                          </Stack>
                        }
                        isMobile={isMobile}
                        mobileTittle={
                          <Stack direction={'row'} spacing={1}>
                            <span>{'Письмо №'}</span>
                            <span>{loading ? <Skeleton width={'100px'} height={'32px'} /> : letter?.number}</span>
                          </Stack>
                        }
                      />
                    </Grid>
                    <Grid item={true} mt={isMobile ? 1 : 0}>
                      <Typography variant={'label1'} color={(theme: Theme) => theme.colors.grayText}>
                        {loading ? (
                          <Skeleton width={'90px'} height={'16px'} />
                        ) : letter ? (
                          formatShortDateTimeString(letter.sentDate)
                        ) : (
                          ''
                        )}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                {isMobile && (
                  <Grid item={true}>
                    <MobileLetterHistory events={events} isLoading={eventLogLoading} />
                  </Grid>
                )}
                <Grid item={true}>
                  <Grid container={true} direction={'column'} spacing={isMobile ? 4 : 5}>
                    <Grid item={true}>
                      <Grid container={true} direction={'column'} spacing={4}>
                        <Grid item={true}>
                          <LetterView
                            letter={letter}
                            isLoading={loading}
                            onAttachFileClick={(attachFile: AttachFileViewDTO): void => {
                              setImageViewName(attachFile?.name);
                              setImageViewContent(attachFile?.content);
                            }}
                            onEnclosureClick={(enclosure: EnclosureViewDTO): void => {
                              setImageViewName(getEnclosureName(enclosure?.type));
                              setImageViewContent(enclosure?.content);
                            }}
                          />
                        </Grid>
                        {answer && (
                          <Fragment>
                            <Grid item={true}>
                              <Divider />
                            </Grid>
                            <Grid item={true}>
                              <AnswerViewBlock answer={answer} />
                            </Grid>
                          </Fragment>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            {!isMobile && (
              <Grid item={true} xs={3}>
                <LetterHistory events={events} isLoading={eventLogLoading} />
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
      <ImageViewDialog
        open={!!imageViewContent}
        content={imageViewContent || ''}
        title={
          <Grid container={true} direction={'row'} alignItems={'center'} spacing={2}>
            <Grid item={true}>
              <Typography variant={'h1'} fontSize={'24px'} lineHeight={'20px'}>
                {`Вложение письма № ${letter?.number}`}
              </Typography>
            </Grid>
            <Grid item={true}>
              <Typography
                variant={'label2'}
                fontSize={'12px'}
                lineHeight={'20px'}
                color={(theme: Theme) => theme.colors.grayText}>
                {imageViewName}
              </Typography>
            </Grid>
          </Grid>
        }
        onClose={() => setImageViewContent(undefined)}
      />
    </Fragment>
  );
};
